.face-1 {
  position: relative;
  z-index: 1;
  transition: all 0.3s;
  background-color: #fff;
  padding-top: 1rem;
  padding: 1rem 1.2rem 0;
}

.card {
  background-color: #fff;
  height: 100%;
}

a:link a:visited {
  text-decoration: none;
  color: black;
}

.service {
  height: 100%;
}

.card:hover .face-1 {
  transform: translateY(-50px);
}

.face-2 {
  position: relative;
  transform: translateY(-100%);
  transition: all 0.3s;
  display: flex;
  justify-content: space-around;
  padding: 0.8rem;
}

.card:hover .face-2 {
  transform: translateY(65%);
  background-color: #ccf2fc;
}

.face-2 p {
  align-self: center;
}

.btn-service {
  border: none;
  border-radius: 11px;
  background-color: #00bff2;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1.6rem;
  padding: 1.2rem;
  align-self: center;
  transition: all 0.3s;
}
.btn-service:hover {
  color: #fff;
}
