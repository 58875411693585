.face-1 {
  z-index: 1;
  background-color: #fff;
  padding: 1rem 1.2rem 0;
  transition: all .3s;
  position: relative;
}

.card {
  height: 100%;
  background-color: #fff;
}

a:link a:visited {
  color: #000;
  text-decoration: none;
}

.service {
  height: 100%;
}

.card:hover .face-1 {
  transform: translateY(-50px);
}

.face-2 {
  justify-content: space-around;
  padding: .8rem;
  transition: all .3s;
  display: flex;
  position: relative;
  transform: translateY(-100%);
}

.card:hover .face-2 {
  background-color: #ccf2fc;
  transform: translateY(65%);
}

.face-2 p {
  align-self: center;
}

.btn-service {
  cursor: pointer;
  white-space: nowrap;
  background-color: #00bff2;
  border: none;
  border-radius: 11px;
  align-self: center;
  padding: 1.2rem;
  font-size: 1.6rem;
  transition: all .3s;
}

.btn-service:hover {
  color: #fff;
}

/*# sourceMappingURL=index.9e416d5c.css.map */
